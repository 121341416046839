export const initKvImageSwitcher = () => {
  const kvImages = document.querySelectorAll('.kv-image'); // .kv-imageを全て取得
  let currentIndex = 0; // 現在表示されている画像のインデックス

  // 初期状態として1枚目の画像を表示
  if (kvImages.length > 0) {
    kvImages[currentIndex].classList.add('active');
  }

  const changeContent = () => {
    if (kvImages.length > 0) {
      // 現在の画像を非表示にする
      kvImages[currentIndex].classList.remove('active');

      // 次のインデックスを計算 (ループ処理)
      currentIndex = (currentIndex + 1) % kvImages.length;

      // 次の画像を表示
      kvImages[currentIndex].classList.add('active');
    }
  };

  // x秒ごとに画像を切り替える
  setInterval(changeContent, 6000);
};
