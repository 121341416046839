export const initCurrentNavHighlight = () => {
  const currentPath = window.location.pathname.replace(/\/$/, ""); // 現在のURLのパスを取得
  const navLinks = document.querySelectorAll('.g-nav .item a'); // ナビゲーション内の全てのリンクを取得

  navLinks.forEach(link => {
    const linkPath = new URL(link.href).pathname.replace(/\/$/, ""); // 各リンクのパス部分を取得

    // 下層リンクまたは親リンクが現在のURLと一致する場合
    if (linkPath === currentPath) {
      link.classList.add('-current'); // 一致したリンクにカレントクラスを追加

      // 親リンクの特定とクラス付与
      const navItem = link.closest('.item'); // 親リンクを含むli.item
      if (navItem) {
        const parentLink = navItem.querySelector('.parent.pc'); // 親リンク(pc用)
        const parentButton = navItem.querySelector('.parent.sp'); // 親ボタン(sp用)

        if (parentLink) parentLink.classList.add('-current');
        if (parentButton) parentButton.classList.add('-current');
      }
    }
  });
};
