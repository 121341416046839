export const adjustNewsAreaBottom = () => {
  const updateNewsArea = () => {
    const newsArea = document.querySelector('.news-area');
    const kvElement = document.querySelector('.kv');

    if (!newsArea || !kvElement) {
      // 要素が見つからない場合は再試行
      requestAnimationFrame(() => {
        setTimeout(updateNewsArea, 100);
      });
      return;
    }

    // DOMの更新を確実に取得するため、少し遅延を入れる
    setTimeout(() => {
      const kvHeight = kvElement.offsetHeight;

      // heightが0の場合は画像などのリソースがまだ読み込まれていない可能性がある
      if (kvHeight === 0) {
        requestAnimationFrame(() => {
          setTimeout(updateNewsArea, 100);
        });
        return;
      }

      document.documentElement.style.setProperty('--kv-height', `${kvHeight}px`);

      if (window.innerWidth > 768) {
        newsArea.style.top = `calc(${kvHeight}px - 140px)`;
      } else {
        newsArea.style.top = `${kvHeight}px`;
      }
    }, 100);
  };

  // DOMContentLoadedとloadの両方でイベントを設定
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', updateNewsArea);
  } else {
    updateNewsArea();
  }

  window.addEventListener('load', updateNewsArea);

  // リサイズイベントの最適化
  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(updateNewsArea, 100);
  });
};
