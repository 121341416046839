export const updateHeaderHeight = () => {
  const header = document.querySelector('.header');
  if (header) {
    requestAnimationFrame(() => {
      const headerHeight = header.offsetHeight - 1;
      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
      document.documentElement.classList.add('header-ready');
    });
  }
};

export const initHeaderHeight = () => {
  setTimeout(updateHeaderHeight, 100);
  window.addEventListener('resize', updateHeaderHeight);
};

// ヘッダーの表示/非表示の制御
export const initHeaderVisibility = () => {
  const header = document.querySelector('header');
  let prevY = window.scrollY;
  const threshold = 80;
  let disableScrollHandling = false;

  const headerHidden = () => {
    if (disableScrollHandling) return;

    const currentY = window.scrollY;
    const scrollDifference = Math.abs(currentY - prevY);

    if (currentY > threshold) {
      if (currentY < prevY) {
        header.classList.remove('hidden');
      } else if (scrollDifference > 1) {
        // 1px以上スクロールした場合に隠す
        header.classList.add('hidden');
      }
    } else {
      header.classList.remove('hidden');
    }

    prevY = currentY;
  };

  window.addEventListener('scroll', headerHidden);
};
