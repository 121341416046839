export const initPopupToggle = () => {
  const touchIcons = document.querySelectorAll('.touch-icon');
  const popups = document.querySelectorAll('.pop-up .content');

  // モーダルを開く処理
  touchIcons.forEach((touchIcon) => {
    const id = touchIcon.getAttribute('data-id');
    touchIcon.addEventListener('click', () => {
      const correspondingPopup = document.querySelector(`.pop-up .content[data-id="${id}"]`);
      if (correspondingPopup) {
        correspondingPopup.classList.toggle('-active');
      }
    });
  });

  // モーダルを閉じる処理 (×ボタン)
  const closeButtons = document.querySelectorAll('.pop-up .close');
  closeButtons.forEach((closeButton) => {
    closeButton.addEventListener('click', () => {
      const correspondingPopup = closeButton.closest('.content');
      if (correspondingPopup) {
        correspondingPopup.classList.remove('-active');
      }
    });
  });

  // モーダル外をクリックして閉じる処理
  document.addEventListener('click', (event) => {
    popups.forEach((popup) => {
      if (popup.classList.contains('-active')) {
        // クリックがモーダル内部ではない場合に閉じる
        if (!popup.contains(event.target) && !event.target.closest('.touch-icon')) {
          popup.classList.remove('-active');
        }
      }
    });
  });
};
