// assets/js/utilities/utilitiesAll.js
import { loadHeaderFooter } from './loadHeaderFooter';
import { updateHeaderHeight, initHeaderHeight, initHeaderVisibility } from './headerProcessing';
import { initNewsItemSwitcher } from './newsItemSwitcher';
import { initTabs } from './tabs';
import { initStickyContact } from './stickyContact';
import { initPopupToggle } from './popupToggle';
import { adjustNewsAreaBottom } from './newsAreaAdjust';
import { initModal } from './modalToggle';
import { initLocalBlockToggle } from './localBlockToggle';
import { initSupportPulldown } from './pulldownSupport';
import { initKvImageSwitcher } from './kvImageSwitcher';

export default async function utilitiesAll() {
  await loadHeaderFooter(); // ヘッダーとフッターのロードを待つ
  initHeaderHeight();
  initHeaderVisibility();
  initTabs();
  initStickyContact();
  initPopupToggle();
  initModal();
  initLocalBlockToggle();
  initSupportPulldown();
  initKvImageSwitcher();

  // TOPページのみで adjustNewsAreaBottom を実行
  const path = window.location.pathname.replace(/\/$/, ''); // トレイリングスラッシュを削除
  if (path === '' || path === '/index.html') {
    initNewsItemSwitcher();
    adjustNewsAreaBottom();
  }

  // load イベントに関する処理
  window.addEventListener('load', async () => {
    await updateHeaderHeight();
  });
}
