export const initLocalBlockToggle = () => {
  const rows = document.querySelectorAll('.js-open-local'); // すべての .js-open-local 要素を取得

  if (rows.length === 0) return; // .js-open-local が存在しない場合は終了

  rows.forEach((row) => {
    row.addEventListener('click', (event) => {
      // .block をクリックした場合は処理を終了
      if (event.target.closest('.block')) return;

      // .block が存在するか確認
      const block = row.querySelector('.block');
      if (!block) return;

      // 現在の .js-open-local に .-active が付与されている場合は削除
      if (row.classList.contains('-active')) {
        row.classList.remove('-active'); // クラスを削除して閉じる
        return;
      }

      // 他のモーダルを閉じないので、現在のモーダルのみを開閉
      row.classList.add('-active'); // 現在の .js-open-local に .-active を付与
    });
  });
};
