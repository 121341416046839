export const initTabs = () => {
  const tabs = document.querySelectorAll('.js-tab');
  const panels = document.querySelectorAll('.js-tab-panel');

  const resetTab = () => {
    document.querySelector('.js-tab.-active').classList.remove('-active');
    document.querySelector('.js-tab[aria-selected="true"]').removeAttribute('aria-selected');
    tabs.forEach(tab => (tab.tabIndex = -1));
    document.querySelector('.js-tab-panel.-active').classList.remove('-active');
  };

  const setTab = (tab, tabpanel) => {
    tab.classList.add('-active');
    tab.tabIndex = 0;
    tab.setAttribute('aria-selected', true);
    tabpanel.classList.add('-active');
  };

  const tabSwitch = event => {
    let tabsArray = Array.from(tabs);
    let index = tabsArray.indexOf(event.currentTarget);

    if (event.type === 'keyup') {
      if (event.key === 'ArrowRight') {
        index = (index + 1) % tabsArray.length;
      } else if (event.key === 'ArrowLeft') {
        index = (index - 1 + tabsArray.length) % tabsArray.length;
      }
      tabsArray[index].focus();
    }

    resetTab();
    setTab(tabsArray[index], panels[index]);
  };

  tabs.forEach(tab => {
    tab.addEventListener('click', tabSwitch);
    tab.addEventListener('keyup', tabSwitch);
  });
};