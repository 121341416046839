import { initHamburgerMenu } from './hamburgerMenu';
import { initDropdownToggle } from './dropdownToggle';
import { initCurrentNavHighlight } from './currentNavHighlight';

export const loadHeaderFooter = async () => {
  const loadContent = async (container, callback) => {
    const src = container.getAttribute('data-src');
    if (!src) return;

    try {
      const response = await fetch(src);
      if (!response.ok) {
        console.error(`Failed to load ${src}`);
        container.innerHTML = '<p></p>'; // フォールバック
        return;
      }
      const html = await response.text();
      container.innerHTML = html;
      if (callback) callback(); // HTMLを挿入後にコールバック関数を実行
    } catch (error) {
      console.error(`Error loading content for ${container.id}:`, error);
      container.innerHTML = '<p></p>'; // フォールバック
    }
  };

  // ヘッダーとフッターを並列で読み込む
  const headerContainer = document.querySelector('#header');
  const footerContainer = document.querySelector('#footer');

  const headerPromise = headerContainer
    ? loadContent(headerContainer, () => {
        if (document.querySelector('.hamburger-button-wrap')) {
          initHamburgerMenu();
        }
        if (document.querySelector('.g-nav')) {
          initDropdownToggle();
        }
        if (document.querySelector('.g-nav .item a')) {
          initCurrentNavHighlight();
        }
      })
    : Promise.resolve();

  const footerPromise = footerContainer
    ? loadContent(footerContainer)
    : Promise.resolve();

  await Promise.all([headerPromise, footerPromise]);
};
