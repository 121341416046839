export const initModal = () => {
  const openModalBtns = document.querySelectorAll('.js-open-modal'); // 開くボタン
  const closeModalBtns = document.querySelectorAll('.js-close-modal'); // 閉じるボタン
  const modals = document.querySelectorAll('dialog.modal'); // 全モーダルを取得

  if (openModalBtns.length === 0 || modals.length === 0) return; // 要素がなければ終了

  // モーダルを開く処理
  openModalBtns.forEach((openModalBtn) => {
    openModalBtn.addEventListener('click', (event) => {
      const targetModalId = openModalBtn.dataset.target; // 対象モーダルのIDを取得
      const targetModal = document.getElementById(targetModalId);

      if (targetModal) {
        document.documentElement.style.overflow = 'hidden'; // ページスクロールを無効化
        targetModal.showModal(); // モーダルを表示
      }
    });
  });

  // モーダルを閉じる処理
  closeModalBtns.forEach((closeModalBtn) => {
    closeModalBtn.addEventListener('click', (event) => {
      const targetModal = closeModalBtn.closest('dialog.modal'); // 閉じる対象のモーダル
      if (targetModal) {
        document.documentElement.style.overflow = ''; // ページスクロールを有効化
        targetModal.close(); // モーダルを閉じる
      }
    });
  });

  // モーダル外クリック時の処理
  modals.forEach((modal) => {
    modal.addEventListener('click', (event) => {
      const innerContent = modal.querySelector('.modal-inner'); // モーダル内部コンテンツを取得

      if (!innerContent) return; // .modal-inner が存在しない場合は何もしない

      const rect = innerContent.getBoundingClientRect(); // 内部コンテンツの位置とサイズを取得
      const isOutside =
        event.clientX < rect.left || event.clientX > rect.right || // X座標の外側判定
        event.clientY < rect.top || event.clientY > rect.bottom;  // Y座標の外側判定

      if (isOutside) {
        document.documentElement.style.overflow = ''; // ページスクロールを有効化
        modal.close(); // モーダルを閉じる
      }
    });
  });
};
