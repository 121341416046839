export const initNewsItemSwitcher = () => {
  const newsItems = document.querySelectorAll('.news-item');
  let currentIndex = 0;

  newsItems.forEach((item, index) => {
    if (index !== currentIndex) {
      item.style.display = 'none';
    }
  });

  const fadeIn = (element) => {
    element.style.opacity = 0;
    element.style.display = 'grid';
    element.style.transition = 'opacity 1s';
    setTimeout(() => {
      element.style.opacity = 1;
    }, 10);
  };

  const fadeOut = (element, callback) => {
    element.style.opacity = 1;
    element.style.transition = 'opacity 1s';
    setTimeout(() => {
      element.style.opacity = 0;
      setTimeout(() => {
        element.style.display = 'none';
        callback();
      }, 1000);
    }, 10);
  };

  const updateNews = () => {
    const currentItem = newsItems[currentIndex];
    const nextIndex = (currentIndex + 1) % newsItems.length;
    const nextItem = newsItems[nextIndex];

    fadeOut(currentItem, () => {
      fadeIn(nextItem);
      currentIndex = nextIndex;
    });
  };

  setInterval(updateNews, 8000);
};
