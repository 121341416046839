export const initHamburgerMenu = () => {
  const hamburgerButtonWrap = document.querySelector('.hamburger-button-wrap');
  const header = document.querySelector('#header');

  // 要素が存在しなければ処理を終了
  if (!hamburgerButtonWrap || !header) return;

  hamburgerButtonWrap.addEventListener('click', () => {
    header.classList.toggle('is-active');
    document.body.style.overflow = document.body.style.overflow === 'hidden' ? 'auto' : 'hidden';
  });
};
