export const initStickyContact = () => {
  const stickyContact = document.querySelector('.sticky-contact');
  const secContact = document.querySelector('#sec-contact');

  if (!stickyContact || !secContact) return;

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      const rect = secContact.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        stickyContact.style.position = 'absolute';
        stickyContact.style.bottom = 'unset';
        stickyContact.style.top = `${secContact.offsetTop - stickyContact.offsetHeight}px`;
      } else {
        stickyContact.style.position = 'fixed';
        stickyContact.style.top = 'unset';
        stickyContact.style.bottom = '0';
      }
    });
  };

  const observerOptions = {
    root: null,
    threshold: 0,
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(secContact);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 200) {
      stickyContact.style.transform = 'translateY(0)';
    } else {
      stickyContact.style.transform = 'translateY(100%)';
    }
  };

  window.addEventListener('scroll', handleScroll);
};