export const initDropdownToggle = () => {
  const mediaQuery = window.matchMedia('(min-width: 769px)');
  const items = document.querySelectorAll('.g-nav .item');
  const buttons = document.querySelectorAll('.parent');

  // 要素が存在しなければ処理を終了
  if (items.length === 0 || buttons.length === 0) return;

  const toggleDropdown = (dropdown, isActive) => {
    if (dropdown) {
      dropdown.classList.toggle('-active', isActive);
    }
  };

  const handleHover = (event) => {
    const item = event.currentTarget;
    const dropdown = item.querySelector('.drop-down');
    toggleDropdown(dropdown, event.type === 'mouseenter');
  };

  const handleClick = (event) => {
    const button = event.currentTarget;
    const dropdown = button.nextElementSibling;
    if (dropdown && dropdown.classList.contains('drop-down')) {
      toggleDropdown(dropdown, !dropdown.classList.contains('-active'));
    }
  };

  const applyEventListeners = () => {
    if (mediaQuery.matches) {
      items.forEach(item => {
        item.addEventListener('mouseenter', handleHover);
        item.addEventListener('mouseleave', handleHover);
      });
      buttons.forEach(button => button.removeEventListener('click', handleClick));
    } else {
      buttons.forEach(button => button.addEventListener('click', handleClick));
      items.forEach(item => {
        item.removeEventListener('mouseenter', handleHover);
        item.removeEventListener('mouseleave', handleHover);
      });
    }
  };

  mediaQuery.addListener(applyEventListeners);
  applyEventListeners();
};
