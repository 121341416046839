export const initSupportPulldown = () => {
  const regions = {
    hokkaido: ['hokkaido'],
    tohoku: ['aomori', 'iwate', 'miyagi', 'akita', 'yamagata', 'fukushima'],
    kanto: ['ibaraki', 'tochigi', 'gunma', 'saitama', 'chiba', 'tokyo', 'kanagawa'],
    chubu: ['niigata', 'toyama', 'ishikawa', 'fukui', 'yamanashi', 'nagano', 'gifu', 'shizuoka', 'aichi'],
    kinki: ['mie', 'shiga', 'kyoto', 'osaka', 'hyogo', 'nara', 'wakayama'],
    chugoku: ['tottori', 'shimane', 'okayama', 'hiroshima', 'yamaguchi'],
    shikoku: ['tokushima', 'kagawa', 'ehime', 'kochi'],
    kyushu: ['fukuoka', 'saga', 'nagasaki', 'kumamoto', 'oita', 'miyazaki', 'kagoshima'],
    okinawa: ['okinawa']
  };

  const pulldown = document.getElementById('pulldown-support');
  if (!pulldown) return;

  pulldown.addEventListener('change', (event) => {
    const selectedArea = event.target.value;
    filterContent(selectedArea);
  });

  const filterContent = (selectedArea) => {
    const rows = document.querySelectorAll('.row.js-open-local');

    rows.forEach(row => {
      const supportAreas = row.getAttribute('data-support') || '';
      const isRegionSelected =
        Array.isArray(regions[selectedArea]) &&
        regions[selectedArea].some(region => supportAreas.includes(region));

      const isAreaSelected =
        (supportAreas && selectedArea && supportAreas.includes(selectedArea)) ||
        (supportAreas && getRegionName(selectedArea) && supportAreas.includes(getRegionName(selectedArea)));

      if (selectedArea === 'all' || isRegionSelected || isAreaSelected) {
        row.classList.remove('hidden');
      } else {
        row.classList.add('hidden');
      }
    });

    hideEmptySections();
  };

  const getRegionName = (area) => {
    for (const [region, areas] of Object.entries(regions)) {
      if (areas.includes(area)) {
        return region;
      }
    }
    return null;
  };

  const hideEmptySections = () => {
    const sections = document.querySelectorAll('.area-block');

    sections.forEach(section => {
      const rows = section.querySelectorAll('.row.js-open-local');
      const visibleRows = Array.from(rows).some(row => !row.classList.contains('hidden'));

      if (visibleRows) {
        section.classList.remove('hidden');
      } else {
        section.classList.add('hidden');
      }
    });
  };
};
